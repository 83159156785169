.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.form-login {
    width: 30vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 4px solid rgb(251, 194, 194);
    align-items: center;
    justify-content: center;
    gap: 1.2em;
    background: rgb(35,75,152);
    border-radius: 25px;
    background: linear-gradient(212deg, rgba(35,75,152,0.9276960784313726) 0%, rgba(16,68,79,0.9501050420168067) 100%);
}

input.username,
input.password {
    display: flex;
    border-radius: 15px;
    height: 2.8em;
    padding: 1.5em;
    padding-left: 30px;
}

.otp-container,
.username-container,
.password-container {
    position: relative;
    width: 70%;
}

.username,
.password {
    width: 100%;
    padding-right: 20px;
    padding-left: 40px;
}

.bi.bi-asterisk,
.bi.bi-lock,
.bi.bi-person {
    position: absolute;
    top: 31%;
    left: 10px;
}
 
.sendOTP {
    position: absolute;
    right: 0px;
    top: 0;
    cursor: pointer;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #104781c9;
    background-color: rgba(17, 87, 130, 0.807);
    border-radius: 15px;
    color: rgba(214, 214, 214, 0.875);
    font-weight: 600;
    gap: 0.3em;
    transition: transform 0.4s ease;
}

.sendOTP:hover {
    transform: scale(1.1);
    background-color: rgb(47, 101, 134);
}

.sendOTP.disabled {
    cursor: wait;
    opacity: 0.6; 
}

.sendOTP.disabled:hover {
    transform: none;
}

.bx.bx-mail-send {
    font-size: 1.8em;
    color: rgba(214, 214, 214, 0.804);
    font-weight: 600;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remember {
    display: flex;
    color: #fff;
}

.register {
    display: flex;
    flex-direction: row;
    gap: 0.3em;
}

.forgot-password {
    margin-left: 30px;
}

.register p, a,
.forgot-password p{
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}


.register .register-btn:hover,
.forgot-password p:hover {
    font-weight: bold;
    color: rgb(194, 227, 255);
    transition: font-weight 0.3s ease;
}

.popup-player{
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(251, 0, 0, 0.9);
    color: white;
    padding: 1.5em;
    border-radius: 0.5em;
    z-index: 1000;
    text-align: center;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}
.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(251, 0, 0, 0.9);
    color: white;
    padding: 1.5em;
    border-radius: 0.5em;
    z-index: 1000;
    text-align: center;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.popup-player.success,
.popup.success {
    background-color: rgba(32, 125, 35, 0.736);
    animation: none; 
}

@keyframes shake {
    0% { transform: translate(-50%, -50%) translateX(0); }
    10%, 90% { transform: translate(-50%, -50%) translateX(-1px); }
    20%, 80% { transform: translate(-50%, -50%) translateX(2px); }
    30%, 50%, 70% { transform: translate(-50%, -50%) translateX(-4px); }
    40%, 60% { transform: translate(-50%, -50%) translateX(4px); }
}

.popup-player p,
.popup p {
    margin: 0;
    font-size: 1.2em;
}

.username-container.error input,
.password-container.error input {
  border: 2px solid red;
}

.login-btn button {
    width: 90px;
    height: 45px;
    background-color: rgba(144, 248, 248, 0.89);
    border-radius: 25px;
}
.profile-page {
    width: 100%;
    height: 90vh;
    color: #fff;
    display: flex;
    flex-direction: row;
    padding: 8em;
}

.side-tab {
    height: 7em;
    width: 9.3em;
    display: flex;
    flex-direction: column;
    margin-top: 0.4em;
}

.side-tab .hoso-item {
    border: 3px solid rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
    margin-left: 0.2em;
}

.profile-page .info {
    border: 3px solid rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    font-size: 3.2em;
    font-weight: 600;
    padding-left: 0.8em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    background-color: rgb(14, 24, 41);
    z-index: 1;
}

.info .change-password {
    display: flex;
    align-items: center;
    gap: 0.8em;
}

.side-tab .hoso-item {
    border: 3px solid rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.side-tab .hoso-item.active {
    background-color: rgb(97, 108, 191);
    transform: scale(1.2); 
    z-index: 1;
}

.side-tab .hoso-item:not(.active) {
    transform: scale(1);
    z-index: 0;
}

.side-tab .hoso-item:not(.active):hover {
    transform: scale(1.1);
}

.info p button {
    color: #fff;
    background-color: rgba(28, 56, 90, 0.829);
    width: 8.3em;
    height: 2.2em;
    border-radius: 12px;
    margin-top: 0.8em;
    border: none;
    cursor: pointer;
    transition: transform 0.6s ease;
}

.info p button:hover {
    background-color: rgba(6, 40, 82, 0.896);
    transform: scale(1.1);
}

.favorites-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0.3em;
    gap: 0.2em;
    overflow: auto;
}

.favorites-container::-webkit-scrollbar {
    display: none;
}

.favorites-container .movie-item {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(255, 255, 255);
    height: 3em;
    width: 100%;
    gap: 0.4em;
    background-color: #031726c6;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
}

.favorites-container .movie-item img { 
    width: 2.3em;
    height: 100%;
    border-radius: 10px;
}

.change-pass {
    display: none !important;
  }
  
.change-pass.active {
    display: flex !important;
    align-items: center;
    font-size: 0.7em;
    gap: 0.4em;
}

.change-pass input {
    height: 2.6em;
    margin-top: 0.6em;
    border-radius: 20px;
    text-align: center;
    background-color: rgb(30, 31, 53);
    color: #fff;
    border: none;
}

.change-pass button {
    height: 2.6em;
    margin-top: 0.6em;
    border-radius: 20px;
    width: 7.6em;
    border: 1px solid #fff;
    background-color: rgba(61, 116, 204, 0.812);
    color: #fff;
    transition: transform 0.4s ease;
    cursor: pointer;
}

.change-pass button:active {
    background-color: rgba(35, 73, 135, 0.692);
} 


.change-pass button:hover {
    transform: scale(1.05);
}
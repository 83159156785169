.library-container {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3em;
    gap: 2em;
}

.library-container .new-movie-container {
    height: max-content;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.new-movie-container::-webkit-scrollbar{
    background-color: rgb(9, 28, 62);
    border-radius: 15px;
}

.new-movie-container::-webkit-scrollbar-thumb {
    background: rgba(16, 40, 94, 0.841);
    border-radius: 15px; 
    border: 3px solid #fff;
}

.library-container .new-movie-container .movie-content {
    flex: 0 0 auto; 
    width: calc(100% / 7 - 1em); 
    box-sizing: border-box;
}


.library-container h1 {
    padding: 5px;
    width: 100%;
    background: linear-gradient(
        to bottom right, 
        rgba(8, 8, 38, 0.778) 49%, 
        rgba(95, 111, 112, 0.522) 51%
    );
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid #fff;
}


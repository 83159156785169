/* google font */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@551&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    scroll-padding-top:2rem;
    scroll-behavior: smooth;
}

.main{
    display: flex;
    flex-direction: column;
    background:#101529;
    height: max-content;
    width: 100%;
    overflow: auto;
}

body::-webkit-scrollbar {
    display: none;
}

.header{
    position: sticky;
    width: 100%;
    top:0;
    right:0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 100px;
    transition: 0.5s;
    background-color: #0a0d19;
}
.logo{
    font-size: 2rem;
    font-weight: 600;
    align-items: center;
    color:#fff;
    display: flex;
    column-gap:0.5em;
}
.bx {
    color: #ff2c1f;
}
.navbar{
    display: flex;
    column-gap: 3rem;
}
.navbar li {
    position: relative;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #0a0d19;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    list-style-type: none;
    padding: 0;
    margin-top: 0.4em;
}

.dropdown-content li {
    padding: 8px 12px;
}

.dropdown-content a {
    text-decoration: none;
    color: #fff;
    display: block;
}

.dropdown-content li:nth-child(1),
.dropdown-content li:nth-child(2),
.dropdown-content li:nth-child(3){
    margin-top: 1.4em;
}

.dropdown-content li {
    padding: 8px 12px;
}

.dropdown-content a {
    text-decoration: none;
    color: #fff;
    display: contents;
}

.dropdown:hover .dropdown-content {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 0.3em;
}

.profile-dropdown {
    display: none;
    position: absolute;
    background-color: #0a0d19;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding: 10px;
}

.profile-dropdown li {
    padding: 8px 12px;
    margin-top: 10px;
}

.profile-dropdown a {
    text-decoration: none;
    color: #fff;
    display: block;
}

.profile-dropdown li:hover {
    background-color: #11172b;
}
.profile-container:hover .profile-dropdown {
    display: block;
}

.navbar a{
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
}

.navbar ul li::after,
.navbar a::after{
    content: "";
    width: 0;
    height: 2px;
    background-color: #ff2c1f;
    position: absolute;
    bottom: -4px;
    left:0;
    transition: 0.4s all linear;
}

li:hover::after,
.navbar a:hover::after,
.navbar .active::after{
    width: 100%;
}
#menu-icon{
    font-size: 24px;
    cursor: pointer;
    z-index: 1000001;
    display: none;
}

.profile-container{
    height: 60px;
    width: 60px;
    padding: 5px;
}

.profile-container img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border: 1px solid rgba(20, 16, 65, 0.767);
    background-color: #101732;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    padding: 0.7rem 1.4rem;
    background: rgb(70,129,244);
    background: radial-gradient(circle, rgba(70,129,244,1) 0%, rgba(85,194,218,1) 100%);
    font-weight: 400;
    border-radius: 0.5rem;
    color: #fff;
}

.header .search-bar {
    height: 4vh;
    width: 25vw;
}

.header .search-bar .search {
    height: 100%;
    width: 100%;
    border-radius: 25px;
    background-color: #151a30e9;
    color: #fff;
    padding: 10px;
    border: 3px solid #131829d9;
    text-align: center;
}

.header .search-bar .search::placeholder {
    text-align: center;
    color: #fff;
}

.header .search-bar .search:focus::placeholder {
    opacity: 0;
}

.header .search-bar .search:focus {
    background-color: rgba(35, 42, 62, 0.803);
    border: 3px solid #131829d9;
}

.body_home{
    margin-top: 0.7em;
    width: 100%;
    height: 100%;
    min-height: 89vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.body_home::-webkit-scrollbar {
    display: none;
}

.body_home .recommend {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    gap: 1.8em;
    overflow: hidden;
}

.body_home .recommend img {
    width: 100%;     
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    display: block; 
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.8s ease;
}

.body_home .recommend img:hover {
    transform: scale(1.1);
}
.body_home .main-page {
    padding: 0.8em;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1.3em;
    flex-direction: row;
}

.main-page .main-movie {
    width: 100%;
    height: 100%;
}

.main-page .main-movie .nav-movie-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0A0D19;
    border-top-right-radius: 20px;
}

.nav-movie-container h2 {
    padding: 30px;
}


.nav-movie-container .nav-type {
    height: 100%;
    width: 110px;
    border: 1px solid rgb(255, 255, 255);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav-type.selected {
    background-color: #25415ce3; 
    color: #fff;
}

.new-movie-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    grid-gap: 0.6em;
    width: 100%;
    background-color: #151c3bcc;
    border-bottom-right-radius: 20px;
    padding: 10px;
}

.new-movie-container .movie-content {
    background-color: rgba(18, 35, 48, 0.862);
    border: 3px solid #fff;
    color: #fff;
    height: max-content;
    width: 220px;
    overflow: hidden;
    cursor: pointer;
}

img {
    border: 1px solid #fff;
}

.movie-content .img img {
    border-bottom: 3px solid #fff;
    height: 15em;
    width: 100%;
    object-fit: cover;
    transition: filter 0.6s linear;

}

.movie-content .img {
    position: relative;
    height: 100%;
    width: 100%;
}

.movie-content .bx.bx-play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.7em;
    color: white;
    transition: opacity 0.6s linear;
    opacity: 0;
}

.movie-content:hover .img img{
    filter: blur(4px);
}

.movie-content:hover .bx.bx-play-circle {
    opacity: 1;
}

.movie-content .new-nation {
    font-size: 14px;
    font-weight: 600;
}

.movie-content .new-title {
    display: inline-block;
    font-style: bolder;
    font-size: 19px;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
  }
  
@keyframes marquee {
0% {
    transform: translateX(100%);
}
100% {
    transform: translateX(-100%);
}
}
  
.marquee-active {
    animation: marquee 7s linear infinite;
}  

.movie-content .new-nation,
.movie-content .new-title {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 5px;
}

.movie-content .movie-footer {
    padding: 10px;
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.movie-footer .new-year {
    padding-left: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    width: 100%;
}
.movie-footer .new-like {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    width: 40%;
    padding: 5px;
}

.new-like .bi.bi-heart-fill {
    margin-top: 3px;
    margin-right: 4px;
    color: rgba(77, 167, 200, 0.74);
}

.main-page aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    border: 1px solid rgb(27, 26, 26);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1;
    border-radius: 5px;
}

.bxh h1 {
    background-color: #0A0D19;
    height: 1.8em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

h2, h1 {
    color: #fff;
}

.bxh-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.8em;
    color: #fff;
}

.top-view,
.top-rating,
.top-like {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3em;
}
.bxh-content{
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: left;
    row-gap: 0.5em;
    background-color: #141A37;
    padding-top: 20px;
}

.child-content .img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.child-content .img img {
    height: 100%;
    width: 6em;
    border-radius: 5px;
    filter: none;
    transition: filter 0.6s linear;
}

.child-content:hover .img img {
    filter: blur(4px);
}

.child-content {
    display: flex;
    flex-direction: row;
    background-color: hsla(0, 0%, 100%, 0);
    height: 110px;
    border-radius: 5px;
    cursor: pointer;
}

.content-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.content-container .title {
    font-size: 22px;
    font-weight: 400;
    font-weight: bolder;
    overflow: hidden;
}

.bx.bx-bar-chart-alt{
    font-weight: 700;
    padding-left: 10px;
    color: #fff;
}
.content-container .year {
   padding-left: 10px;
   font-weight: 400;
   font-size: 1em;
}
.content-container .title {
    color: aliceblue;
    padding-left: 10px;
    font-weight: 700;
}

.child-content .rank {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-weight: 700;
    font-size: 18px;
}

.content-container .title.rank-1,
.rank.rank-1 {
    color: gold; 
}

.content-container .title.rank-2,
.rank.rank-2 {
    color: silver; 
}

.content-container .title.rank-3,
.rank.rank-3 {
    color: #cd7f32;
}

.child-content .img .bx.bx-play-circle {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 2.8em;
    color: white;
    transition: opacity 0.6s linear;
    opacity: 0;
}

.child-content:hover .img .bx.bx-play-circle {
    opacity: 1;
}

.search-results {
    margin-top: 0.3em;
    display: none;
    position: absolute;
    background-color: rgb(18, 16, 53);
    border: 3px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-height: 500%;
    width: 25vw;
    overflow-y: auto;
    z-index: 1000;
    color: #fff;
  }

.search-results::-webkit-scrollbar{
    display: none;
}
  
.search-results.visible {
    display: block; 
}
  
.search-result-item {
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: transform 0.6s ease;
}
  
.search-result-item:hover {
    background-color: #2a2a40;
    transform: scale(1.07);
}
  
.search-result-item img {
    width: 50px;
    height: 75px;
    margin-right: 10px;
    object-fit: cover;
}

.search-result-item p {
    font-size: 1.5em;
    font-weight: bold;
}
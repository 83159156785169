.player-container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    padding: 1.5em;
}

.main-player-container{
    position: relative;
    min-height: 65em;
    height: max-content;
    width: 70%;
    padding: 1.2em
}

.main-player-container iframe {
    margin-top: 1.2em;
    width: 100%;
    aspect-ratio: 14/7;
}

.main-player-container h3 {
    margin-top: 0.5em;
    margin-bottom: 0.3em;
}

.main-player-container h3,
.main-player-container .chapter-list {
    color: #fff;;
}
.chapter-list ul {
    display: grid;
    grid-template-columns: repeat(10, max-content);
    gap: 0.7em;
}

.chapter-list ul li button {
    color: #fff;
    border-radius: 25px;
    height: 2.7em;
    font-weight: 600;
    width: 4.8em;
    border: 1px solid #fff;
    background-color: rgba(30, 43, 80, 0.648);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.chapter-list ul li button:active {
    transform: scale(1.15);
    background-color: rgba(233, 81, 81, 0.934);
}

.chapter-list ul li button.active {
    background-color: rgba(228, 59, 59, 0.77);
}

.movie-info {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 0.7em;
    display: flex;
    flex-direction: row;
    gap: 0.8em;
}
.movie-info img{
    width: 30%;
    object-fit: cover;
}

.movie-info .info-content {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 0.6em;
}

.main .recommend-player-title {
    margin-bottom: 0.3em;
}

.recommend{
    color: #fff;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: max-content;
    gap: 0.8em;
    justify-content: center;
    align-items: center;
}

.recommend .movie-content {
    width: 180px;
    cursor: pointer;
    border: 3px solid #FFF;
    overflow: hidden;
}

.player-container .comment {
    color: #FFF;
    min-height: 60em;
    max-height: 65em;
    margin-top: 1.2em;
    background-color: rgba(5, 9, 41, 0.944);
    overflow: auto;
    overflow-wrap: break-word;
    padding: 0.7em;
    border-radius: 10px;
}

.no-comment {
    text-align: center;
}

.comment::-webkit-scrollbar {
    background-color: rgb(9, 28, 62);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.comment::-webkit-scrollbar-thumb {
    background: rgba(77, 79, 86, 0.841);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.player-container .comment-box .comment ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    display: inline-block;
}

.player-container .comment-box {
    height: max-content;
    width: 30%;
    margin-top: 0.7em;
}

.player-container .comment-box .comment-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 2.5em;
    gap: 0.3em;
    margin-top: 0.4em;
    top: 0;
    position: sticky;
}

.player-container .comment-box .comment-form textarea {
    height: 100%;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 10px;
    background-color: #FFFFFF; 
    border: 2px solid #CED4DA; 
    color: #495057; 
    font-size: 1em;
    display: flex;
}

.player-container .comment-box .comment-form textarea::placeholder {
    color: #6C757D; 
}

.player-container .comment-box .comment-form button {
    width: 20%;
    height: 100%;
    border-radius: 10px;
    background-color: #007BFF; 
    border: 3px solid #0056b3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.player-container .comment-box .comment-form button:hover {
    background-color: #0056b3; 
    border-color: #007BFF; 
}

.player-container .comment-box .comment-form button i {
    color: #FFFFFF; 
    font-size: 2em; 
}

.recommend-player {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.like-btn {
    background: none;
    border: none;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #050505;
    gap: 0.2em;
    font-weight: 700;
    width: 7em;
    background-color: #FFFFFF;
    border-radius: 12px;
    transition: transform 0.5s ease;
}

.like-amount .bx.bxs-heart {
    font-size: 0.7em;
    scale: 1.4;
    margin-left: 0.3em;
    margin-right: 0.4em;
}

.like-btn .bx.bx-heart,
.like-btn .bx.bxs-heart {
    font-size: 1.4em;
}

.like-btn:hover {
    transform: scale(1.1);
}